import React from 'react';
import { Formik, Form } from 'formik';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';

const MFA = ({ didError, onConfirmCode }) => {
    const initialValues = {
        code: '',
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onConfirmCode}>
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-teal-600 border-b border-teal-600 pt-2 mb-4">
                            Complete MFA
                        </p>

                        <Input name="code" type="number" label="Code" disabled={isSubmitting} />

                        {didError && (
                            <p className="text-sm text-center text-red-700 mb-4">
                                Something went wrong. Please try again.
                            </p>
                        )}

                        <SubmitButton isSubmitting={isSubmitting} text="Continue" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MFA;
