import React, { useEffect, useState } from 'react';

import {
    AUTH_STATUS,
    completeNewPassword,
    confirmMFACode,
    forgotPassword,
    getSession,
    resetPassword,
    signIn,
    signOut,
} from '../../utils/auth';
import Loader from '../shared/Loader';
import CompleteNewPassword from './CompleteNewPassword';
import ForgotPassword from './ForgotPassword';
import MFA from './MFA';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';

export const SCREENS = {
    CHECKING: 'CHECKING',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SIGN_IN: 'SIGN_IN',
    MFA_ENTRY: 'MFA_ENTRY',
    COMPLETE_NEW_PASSWORD: 'COMPLETE_NEW_PASSWORD',
    LOGGED_IN: 'LOGGED_IN',
};

const Authenticator = ({ children }) => {
    const [currentScreen, setCurrentScreen] = useState(SCREENS.CHECKING);
    const [forgottenPasswordUsername, setForgottenPasswordUsername] = useState(null);
    const [didError, setDidError] = useState(null);
    const [signInResult, setSignInResult] = useState(null);

    const onToggleForgotPassword = () => {
        const nextScreen =
            currentScreen === SCREENS.FORGOT_PASSWORD ? SCREENS.SIGN_IN : SCREENS.FORGOT_PASSWORD;

        setCurrentScreen(nextScreen);
    };

    useEffect(() => {
        const checkSession = async () => {
            const session = await getSession();

            setSignInResult(session);
            setCurrentScreen(session ? SCREENS.LOGGED_IN : SCREENS.SIGN_IN);
        };
        setTimeout(() => {
            checkSession();
        }, 500);
    }, []);

    const onSignIn = async (values, { setSubmitting }) => {
        setDidError(null);

        try {
            const result = await signIn(values.userName, values.password);

            setSubmitting(false);

            if (result.status === 'SMS_MFA' || result.status === 'SOFTWARE_TOKEN_MFA') {
                setSignInResult(result);
                setCurrentScreen(SCREENS.MFA_ENTRY);
            } else if (result.status === AUTH_STATUS.NEW_PASSWORD_REQUIRED) {
                setSignInResult(result);
                setCurrentScreen(SCREENS.COMPLETE_NEW_PASSWORD);
            } else {
                const session = await getSession();
                setSignInResult(session);
                setCurrentScreen(SCREENS.LOGGED_IN);
            }
        } catch (error) {
            setSubmitting(false);
            setDidError(error);
            setSignInResult(null);
        }
    };

    const onCompleteNewPassword = async (values, { setSubmitting }) => {
        try {
            await completeNewPassword(signInResult.user, values.newPassword);
            const session = await getSession();
            setSubmitting(false);
            setSignInResult(session);
            setCurrentScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onConfirmCode = async ({ code }, { setSubmitting }) => {
        try {
            await confirmMFACode(signInResult.user, code, signInResult.status);
            const session = await getSession();
            setSubmitting(false);
            setSignInResult(session);
            setCurrentScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onForgotPassword = async (values, { setSubmitting }) => {
        try {
            await forgotPassword(values.userName);
            setSubmitting(false);
            setForgottenPasswordUsername(values.userName);
            setCurrentScreen(SCREENS.RESET_PASSWORD);
        } catch (error) {
            setSubmitting(false);
        }
    };

    const onResendCode = async () => {
        try {
            await forgotPassword(forgottenPasswordUsername);
        } catch (error) {}
    };

    const onResetPassword = async ({ code, newPassword }, { setSubmitting }) => {
        try {
            await resetPassword(forgottenPasswordUsername, code, newPassword);
            const session = await getSession();
            setSubmitting(false);
            setSignInResult(session);
            setCurrentScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onSignOut = async () => {
        await signOut();
        setSignInResult(null);
    };

    if (currentScreen === SCREENS.CHECKING) {
        return (
            <div className="text-teal-600 flex justify-center my-5">
                <Loader size={10} />
            </div>
        );
    }

    if (currentScreen === SCREENS.FORGOT_PASSWORD) {
        return (
            <div className="max-w-lg mx-auto my-4 w-full px-4">
                <ForgotPassword
                    onBackToSignIn={() => {
                        setCurrentScreen(SCREENS.SIGN_IN);
                    }}
                    onForgotPassword={onForgotPassword}
                />
            </div>
        );
    }

    if (currentScreen === SCREENS.RESET_PASSWORD) {
        return (
            <div className="max-w-lg mx-auto my-4 w-full px-4">
                <ResetPassword onResendCode={onResendCode} onResetPassword={onResetPassword} />
            </div>
        );
    }

    if (currentScreen === SCREENS.COMPLETE_NEW_PASSWORD) {
        return (
            <div className="max-w-lg mx-auto my-4 w-full px-4">
                <CompleteNewPassword
                    didError={didError}
                    onCompleteNewPassword={onCompleteNewPassword}
                />
            </div>
        );
    }

    if (currentScreen === SCREENS.MFA_ENTRY) {
        return (
            <div className="max-w-lg mx-auto my-4 w-full px-4">
                <MFA onConfirmCode={onConfirmCode} />
            </div>
        );
    }

    if (currentScreen === SCREENS.LOGGED_IN && signInResult) {
        return (
            <>
                {children}
                <p className="px-6 py-2 text-sm bg-gray-100 text-right border-t border-gray-400">
                    Signed in as {signInResult.username} (
                    <button className="text-teal-600 underline" onClick={onSignOut}>
                        Sign out
                    </button>
                    )
                </p>
            </>
        );
    }

    // Default to show sign in
    return (
        <div className="max-w-lg mx-auto my-4 w-full px-4 w-full px-4">
            <SignIn
                didError={didError}
                onSignIn={onSignIn}
                onToggleForgotPassword={onToggleForgotPassword}
            />
        </div>
    );
};

export default Authenticator;
